import type { IconRootProps } from './Root'
import { IconRoot } from './Root'

export const NeArrow = ({ size = '1.5rem', color = 'currentColor', ...props }: IconRootProps) => {
  return (
    <IconRoot
      size={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.1522 2.78713C12.131 2.78854 12.1094 2.78998 12.0874 2.79144L6.04989 3.19394C5.63659 3.2215 5.27921 2.90879 5.25166 2.49549C5.22411 2.0822 5.53681 1.72482 5.95011 1.69727L12.0347 1.29162C12.4728 1.26235 12.8771 1.23535 13.2059 1.25945C13.5676 1.28597 13.9683 1.38101 14.2937 1.70633C14.619 2.03165 14.714 2.43237 14.7405 2.79411C14.7647 3.12288 14.7376 3.52715 14.7084 3.96533L14.3027 10.0499C14.2752 10.4632 13.9178 10.7759 13.5045 10.7483C13.0912 10.7208 12.7785 10.3634 12.8061 9.95011L13.2086 3.91261C13.21 3.89062 13.2115 3.86901 13.2129 3.84779L2.53033 14.5303C2.23744 14.8232 1.76256 14.8232 1.46967 14.5303C1.17678 14.2374 1.17678 13.7626 1.46967 13.4697L12.1522 2.78713Z"
        fill={color}
      />
    </IconRoot>
  )
}
